const PRODUCTION_HOSTS = ["ark.aviasales.ru", "ark.aviasales.com"]

export const LEGACY_LOCALES = [
  'ar', 'bg', 'cs', 'da', 'de', 'el', 'en', 'es', 'fa', 'fi', 'fr', 'he', 'hi', 'hr', 'hu', 'hy',
  'id', 'it', 'jp', 'ka', 'ko', 'lt', 'lv', 'ms', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl',
  'sr', 'sv', 'th', 'tl', 'tr', 'uk', 'vi', 'en-AU', 'en-CA', 'en-GB', 'en-IE', 'en-IN', 'en-NZ',
  'en-SG', 'pt-BR', 'zh-CN', 'zh-TW', 'zh-Hans', 'zh-Hant',
]

export const COUNTRY_CONTENT_TEXTS_TYPES = [
  'visaContent', 'climateContent', 'timezoneContent', 'moneyContent', 'rentContent'
]
const appHost = window.location.host
export const ARK_ENVIRONMENT = PRODUCTION_HOSTS.some((host) => host === appHost) ?
  "production" : "development";

export const ARK_ACCESS_RULES_KEY: string = 'ark-access-rules';

let graphqlUrl = 'http://localhost:4000/api/admin/gql';
let accessRulesUrl = 'http://localhost:4000/api/admin/access_rules';
if (ARK_ENVIRONMENT === "production") {
  graphqlUrl = 'https://ark.eu-central-1.aviasales.com/api/admin/gql';
  accessRulesUrl = 'https://ark.eu-central-1.aviasales.com/api/admin/access_rules';
} else if (process.env.NODE_ENV === "production") {
  graphqlUrl = '/api/admin/gql';
  accessRulesUrl = '/api/admin/access_rules';
}

export const GRAPHQL_URL: string = graphqlUrl;
export const ARK_ACCESS_RULES_URL: string = accessRulesUrl;

let piosHost = 'http://localhost:4000'
if (appHost === 'ark.aviasales.com') {
  piosHost = 'https://bumagi.aviasales.com';
} else if (appHost === 'ark.aviasales.ru') {
  piosHost = 'https://bumagi.aviasales.ru';
} else if (appHost === 'ark.prod.k8s.int.avs.io') {
  piosHost = 'https://pios.prod.k8s.int.avs.io';
} else if (appHost === 'ark.dev.k8s.int.avs.io') {
  piosHost = 'https://pios.dev.k8s.int.avs.io'
}

export const PIOS_HOST: string = piosHost;
